<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลการย้าย สายการสอนและสายสนับสนุน"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                v-model="times_select"
                :items="manage_time_s"
                item-value="manage_time_s"
                :value="1"
                label="ครั้งที่ :"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                v-model="years_select"
                :items="manage_year_s"
                item-value="manage_year_s"
                :value="2565"
                label="ปีที่ :"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn color="info" large block @click="searchTimeYear()"> <v-icon>mdi-account-search</v-icon> ค้นหา</v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="transference_manages"
          :search="search"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ item.manage_id_ref }}</td>
              <td class="text-center">{{ item.manage_id_card }}</td>
              <td class="text-center">{{ item.frist_name }}</td>
              <td class="text-center">{{ item.last_name }}</td>
              <td class="text-center">{{ item.manage_time_s }}</td>
              <td class="text-center">{{ item.manage_year_s }}</td>
              <td class="text-center">{{ item.manage_age_app_time }}</td>
              <td class="text-center">{{ item.manage_date_time }}</td>
              <td class="text-center">
                <v-chip
                  v-if="item.manage_comment_supervisor"
                  color="green"
                  @click.stop="comment_idPosition(item.manage_id_ref)"
                  dark
                  >{{ item.manage_comment_supervisor }}</v-chip
                >               
                <v-icon
                  large
                  v-else
                  color="info"
                  @click.stop="comment_idPosition(item.manage_id_ref)"
                  >mdi-note-plus</v-icon
                >
              </td>
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

   

      <!-- V-model commentDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="commentDrdialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เพิ่มความคิดเห็นผู้บังคัญบัญชา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
                <div class="red--text">
                   กรณีผู้ยื่นย้ายดำรงตำแหน่งผู้อำนวยการสถานศึกษา ที่มิได้สังกัดสถาบันการอาชีวศึกษา ให้เสนอเอกสารถึง กจ.2 
                </div>
              <v-form ref="updatecommentform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_manages_id_ref.manage_id_ref }}
                        {{ transference_manages_id_ref.manage_id_tfp  }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_manages_id_ref.manage_id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_manages_id_ref.title_s
                        }}{{ transference_manages_id_ref.frist_name }}
                        {{ transference_manages_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_manages_id_ref.college_code }}
                        {{ transference_manages_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_manages_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ : {{ transference_manages_id_ref.manage_time_s }} ปี :
                        {{                          transference_manages_id_ref.manage_year_s
                          
                        }}
                      </h4>
                      <h4>
                        รอบการย้าย :
                        {{
                          periods.period_start
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                        -
                        {{
                          periods.period_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>    
                    <v-col cols="12" md="12">
                      <v-textarea
                        outlined
                        label=" ความเห็นของผู้บังคับบัญชา :"
                        v-model="transference_manages_id_ref.manage_comment_supervisor"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="commentDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatecommentSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      edittransference_managedialog: false,
      times_select: "1",
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_time_s: [1, 2],
      manage_year_s: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_manages: [],
      edittransference_manage: {},
      search: "",
      pagination: {},
      headers: [
        { text: "#", align: "center", value: "index" },
        { text: "อ้างอิง", align: "center", value: "manage_id_ref" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "manage_manage_id_card" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "ครั้งที่", align: "center", value: "manage_time_s" },
        { text: "ปีที่", align: "center", value: "manage_year_s" },
        { text: "อายุงาน ณ ปัจจุบัน", align: "center", value: "manage_manage_age_app_time" },
        { text: "วันที่ทำรายการ", align: "center", value: "manage_manage_date_time" },
        { text: "ความคิดเห็น ผู้บังคับบัญญา", align: "center", value: "actions" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      transference_managestatus: [],
      userstatus: {},
      commentDrdialog: false,
      transference_manages_id_ref: [],
      regions: [],
      periods: [],
      period_enable: "1",
      updatecomment: {},
      branch_s: [],
      branch_sub_ds_select:[],
      branchs_id_sub:[],
    };
  },
  async mounted() {
    await this.periodQuery();
    await this.transference_manageQueryAll();  
    
  },
  methods: {

async branch_sub_d_select() {
      this.loading = true;
      let result = await this.$http
        .post("branch_sub_d.php", {
          ApiKey: this.ApiKey,
          id_main_branch: this.transference_manages_id_ref.id_branch
        })
        .finally(() => (this.loading = false));
      this.branch_sub_ds_select = result.data;
    },     

    async periodQuery() {
     let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
       period_enable_process: "1",
       period_type: "manage"    
      });
      this.periods = result_period.data;
    },

    async searchTimeYear() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.times_select,
          manage_year_s: this.years_select,
          manage_college_code_now: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;
    },

    async comment_idPosition(manage_id_ref) {
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });
      this.transference_manages_id_ref = result_con.data;
      this.commentDrdialog = true;
    },

    async transference_manageQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_college_code_now: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;     
    },
    
    async updatecommentSubmit() {
      if (this.$refs.updatecommentform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.manage_id_ref = this.transference_manages_id_ref.manage_id_ref ;  
        this.updatecomment.manage_comment_supervisor = this.transference_manages_id_ref.manage_comment_supervisor ;  
        let result = await this.$http.post(
          "transference_manage.update.php",
          this.updatecomment
        );

        if (result.data.status == true) {
           Swal.fire({
            icon: 'success',
            title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.transference_manageQueryAll();
        } else {
          Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
        }
        this.commentDrdialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
